import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";

const SmallScreenNavbar = () => {
  let [navBar, setNavBar] = useState(false);
  const handleClick = (e) => {
    if (navBar && e.target.id != "navbar-sticky") {
      setNavBar(!navBar);
    }
  };

  // Close the dropdown menu, once the user navigates to a page

  const toggleMenu = () => {
    setNavBar(!navBar);
  };

  return (
    <>
      {/* Button to toggle menu on smaller screens */}
      <button
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-sticky"
        onClick={toggleMenu}
      >
        <span className="sr-only">Open main menu</span>
        {navBar ? (
          <Image
            src="close.svg"
            alt="close"
            width={22}
            height={22}
            className="dark:filter dark:brightness-0 dark:invert"
          />
        ) : (
          <Image
            src="menu.svg"
            alt="menu"
            width={22}
            height={22}
            className="dark:filter dark:brightness-0 dark:invert"
          />
        )}
      </button>

      <div
        className={`${
          navBar ? "items-center justify-between w-full md:hidden" : "hidden"
        }`}
        id="navbar-sticky"
      >
        <ul className="flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
          <li>
            <div className="relative group">
              {/* Dropdown Button */}
              <button className="block text-md py-2 pl-3 pr-4 text-gray-900 rounded  hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700">
                Health Insurance
                {/* <svg
                  className="inline ml-2 w-3 h-3 text-gray-900 rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 8"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="{2}"
                    d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                  />
                </svg> */}
                <Image
                  src="down.svg"
                  alt="downArrow"
                  width={22}
                  height={22}
                  className="inline ml-1 dark:filter dark:brightness-0 dark:invert"
                />
              </button>
              {/* Dropdown Menu (hidden by default) */}
              <ul className="p-4 mt-1 font-medium rounded-lg bg-gray-50 dark:bg-gray-800 hidden group-hover:block">
                <li>
                  <Link
                    href="/health/health101"
                    className="px-2 py-1 text-gray-800 dark:text-white rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700"
                    onClick={toggleMenu}
                  >
                    Health Insurance 101
                  </Link>
                </li>
                <li>
                  <Link
                    href="/health/healthChecklist"
                    className="px-2 py-1 text-gray-800 dark:text-white rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700"
                    onClick={toggleMenu}
                  >
                    Health Insurance Checklist
                  </Link>
                </li>
                <li>
                  <Link
                    href="/health/healthTopInsurers"
                    className="px-2 py-1 text-gray-800 dark:text-white rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700"
                    onClick={toggleMenu}
                  >
                    Top Health Insurers
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="relative group">
              {/* Dropdown Button */}
              <button className="block text-md py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700">
                Life Insurance
                <Image
                  src="down.svg"
                  alt="downArrow"
                  width={22}
                  height={22}
                  className="inline ml-1 dark:filter dark:brightness-0 dark:invert"
                />
              </button>
              {/* Dropdown Menu (hidden by default) */}
              <ul className="p-4 mt-1 font-medium rounded-lg bg-gray-50 dark:bg-gray-800 hidden group-hover:block">
                <li>
                  <Link
                    href="/life/life101"
                    className="px-2 py-1 text-gray-800 dark:text-white rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700"
                    onClick={toggleMenu}
                  >
                    Term Insurance 101
                  </Link>
                </li>
                <li>
                  <Link
                    href="/life/lifeChecklist"
                    className="px-2 py-1 text-gray-800 dark:text-white rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700"
                    onClick={toggleMenu}
                  >
                    Term Insurance Checklist
                  </Link>
                </li>
                <li>
                  <Link
                    href="/life/zeroCost"
                    className="px-2 py-1 text-gray-800 dark:text-white rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700"
                    onClick={toggleMenu}
                  >
                    Zero Cost term plans
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <button className="block text-md py-2 pr-3 text-gray-900 rounded  hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:border-gray-700">
              <Link
                href="/claimsSupport"
                className="group text-md py-2 pl-3 pr-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                onClick={toggleMenu}
              >
                Claims Support
              </Link>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SmallScreenNavbar;
